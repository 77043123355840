import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Whenever a new major version of Xcode is released, one of the first things I do is download it and run it with the various codebases I'm working on. Usually the new version of Swift is smarter and can surface more warnings and diagnostics, and the Xcode 12 beta release earlier this week (with Swift 5.3) is no exception.`}</p>
    <p>{`However, there was one new warning message the confused me:`}</p>
    <blockquote>
      <p parentName="blockquote">{`expression shuffles the elements of this tuple; this behavior is deprecated`}</p>
    </blockquote>
    <p>{`tl;dr: If you see this message it probably means you're passing a tuple to a method with the named elements in the wrong order, e.g.:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`myMethod`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`tuple`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: (a: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, b: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(tuple)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`myMethod`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`tuple`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: (`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`b`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`a`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// should be myMethod(tuple: (a: 2, b: 1))`}</span></span></code></pre>
    <h1 {...{
      "id": "tuple-shuffling"
    }}>{`Tuple Shuffling`}</h1>
    <p>{`Tuple Shuffling is a deprecated feature of Swift which basically amounts to "you can switch the order of named tuple elements during assignment". If you declare a tuple with named elements in a certain order, you don't have to pass them in the same order.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`myMethod`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`tuple`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: (a: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, b: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(tuple)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// This is perfectly legal, even though b comes before a`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`myMethod`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`tuple`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: (`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`b`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`a`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span></code></pre>
    <p>{`However, Tuple Shuffling is generally something that you shouldn't do, because it can have subtle and confusing side effects. For example, consider the following code:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` a: (`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`y`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) = (`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` b: (`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`y`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) = (`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`a = b`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(a == b) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// prints "false"`}</span></span></code></pre>
    <p>{`This is also perfectly legal code, but it's weird that the two tuples aren't equal, even after you assigned one to the other!`}</p>
    <p>{`This example works because `}<inlineCode parentName="p">{`a = b`}</inlineCode>{` does an implicit Tuple Shuffle - the `}<inlineCode parentName="p">{`y`}</inlineCode>{`th element of `}<inlineCode parentName="p">{`b`}</inlineCode>{` is assigned to the `}<inlineCode parentName="p">{`y`}</inlineCode>{`th element of `}<inlineCode parentName="p">{`a`}</inlineCode>{`, and the first non-named element of `}<inlineCode parentName="p">{`b`}</inlineCode>{` is assigned to the first non-named element of `}<inlineCode parentName="p">{`a`}</inlineCode>{`. Because `}<inlineCode parentName="p">{`a.y`}</inlineCode>{` and `}<inlineCode parentName="p">{`b.y`}</inlineCode>{` were already the same and the first non-named element of each tuple (`}<inlineCode parentName="p">{`a.0`}</inlineCode>{` and `}<inlineCode parentName="p">{`b.1`}</inlineCode>{`) was also the same, the assignment is effectively a no-op.`}</p>
    <p>{`The standard library implementation of `}<inlineCode parentName="p">{`==`}</inlineCode>{` for tuples compares them index-wise (it's equivalent to `}<inlineCode parentName="p">{`a.0 == b.0 && a.1 == b.1`}</inlineCode>{` in this case), and these tuples were never index-wise equal, so `}<inlineCode parentName="p">{`a == b`}</inlineCode>{` returns false.`}</p>
    <p>{`As `}<a parentName="p" {...{
        "href": "https://forums.swift.org/t/deprecating-tuple-shuffles-round-2/16884"
      }}>{`the folks who proposed the Tuple Shuffling deprecation in the forums said`}</a>{`, Tuple Shuffling allows inconsistencies between how Swift works as a language and how the standard library methods work.`}</p>
    <h1 {...{
      "id": "fixing-the-warning"
    }}>{`Fixing the warning`}</h1>
    <p>{`The proposal to deprecate Tuple Shuffling has been around since 2018 - it appears that in Swift 5.3, the warning against shuffling is finally there.`}</p>
    <p>{`The usually warning comes up in practice when you try to pass a tuple of the form `}<inlineCode parentName="p">{`(a: 1, b: 1)`}</inlineCode>{` to an argument that expects a different ordering of names (`}<inlineCode parentName="p">{`(b: Int, a: Int)`}</inlineCode>{`). The fix for this is almost always to pass your named tuple elements in the order they're defined (`}<inlineCode parentName="p">{`a`}</inlineCode>{` first, then `}<inlineCode parentName="p">{`b`}</inlineCode>{`).`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      